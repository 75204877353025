<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-3">
        Match the picture with the description. Pictures from the
        <a
          href="https://chem.libretexts.org/Bookshelves/Organic_Chemistry/Organic_Chemistry_Lab_Techniques_(Nichols)/02%3A_Chromatography/2.03%3A_Thin_Layer_Chromatography_(TLC)/2.3E%3A_Step-by-Step_Procedures_for_Thin_Layer_Chromatography"
        >
          General TLC Procedure
        </a>
        in Chemistry Libretext.
      </p>
      <p class="mb-2">
        <b>[A]</b>
        <v-img style="max-width: 250px" src="/img/assignments/ChemUCI1LDQ2S1Q3_img1.png" />
      </p>
      <p class="mb-3">
        A:
        <v-select
          v-model="inputs.input1"
          style="display: inline-block; max-width: 200px"
          class="ml-1"
          :items="items"
          item-text="text"
          item-value="value"
          label="Select option:"
        >
          <template #item="{item}">
            <stemble-latex class="no-text-transform" :content="item.text" />
          </template>
          <template #selection="{item}">
            <stemble-latex class="no-text-transform" :content="item.text" />
          </template>
        </v-select>
      </p>

      <p class="mb-2">
        <b>[B]</b>
        <v-img style="max-width: 250px" src="/img/assignments/ChemUCI1LDQ2S1Q3_img2.png" />
      </p>
      <p class="mb-3">
        B:
        <v-select
          v-model="inputs.input2"
          style="display: inline-block; max-width: 200px"
          class="ml-1"
          :items="items"
          item-text="text"
          item-value="value"
          label="Select option:"
        >
          <template #item="{item}">
            <stemble-latex class="no-text-transform" :content="item.text" />
          </template>
          <template #selection="{item}">
            <stemble-latex class="no-text-transform" :content="item.text" />
          </template>
        </v-select>
      </p>

      <p class="mb-2">
        <b>[C]</b>
        <v-img style="max-width: 250px" src="/img/assignments/ChemUCI1LDQ2S1Q3_img3.png" />
      </p>
      <p class="mb-3">
        C:
        <v-select
          v-model="inputs.input3"
          style="display: inline-block; max-width: 200px"
          class="ml-1"
          :items="items"
          item-text="text"
          item-value="value"
          label="Select option:"
        >
          <template #item="{item}">
            <stemble-latex class="no-text-transform" :content="item.text" />
          </template>
          <template #selection="{item}">
            <stemble-latex class="no-text-transform" :content="item.text" />
          </template>
        </v-select>
      </p>

      <p class="mb-2">
        <b>[D]</b>
        <v-img style="max-width: 250px" src="/img/assignments/ChemUCI1LDQ2S1Q3_img4.png" />
      </p>
      <p class="mb-3">
        D:
        <v-select
          v-model="inputs.input4"
          style="display: inline-block; max-width: 200px"
          class="ml-1"
          :items="items"
          item-text="text"
          item-value="value"
          label="Select option:"
        >
          <template #item="{item}">
            <stemble-latex class="no-text-transform" :content="item.text" />
          </template>
          <template #selection="{item}">
            <stemble-latex class="no-text-transform" :content="item.text" />
          </template>
        </v-select>
      </p>

      <p class="mb-2">
        <b>[E]</b>
        <v-img style="max-width: 250px" src="/img/assignments/ChemUCI1LDQ2S1Q3_img5.png" />
      </p>
      <p class="mb-3">
        E:
        <v-select
          v-model="inputs.input5"
          style="display: inline-block; max-width: 200px"
          class="ml-1"
          :items="items"
          item-text="text"
          item-value="value"
          label="Select option:"
        >
          <template #item="{item}">
            <stemble-latex class="no-text-transform" :content="item.text" />
          </template>
          <template #selection="{item}">
            <stemble-latex class="no-text-transform" :content="item.text" />
          </template>
        </v-select>
      </p>
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '../StembleLatex.vue';

export default {
  name: 'ChemUCI1LDQ2S1Q3',
  components: {
    StembleLatex,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        input1: null,
        input2: null,
        input3: null,
        input4: null,
        input5: null,
      },
      items: [
        {text: 'Marking the solvent front', value: 'marking'},
        {text: 'TLC plate developing in a chamber', value: 'developing'},
        {text: 'Placing plate into chamber with forceps', value: 'placing'},
        {text: 'Spotting TLC plate with samples', value: 'spotting'},
        {text: 'TLC chamber made with a beaker & a watchglass', value: 'chamberMade'},
      ],
    };
  },
};
</script>
<style scoped></style>
