var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-form',{on:{"submit":function($event){$event.preventDefault();return _vm.submitResponse.apply(null, arguments)}}},[_c('p',{staticClass:"mb-3"},[_vm._v(" Match the picture with the description. Pictures from the "),_c('a',{attrs:{"href":"https://chem.libretexts.org/Bookshelves/Organic_Chemistry/Organic_Chemistry_Lab_Techniques_(Nichols)/02%3A_Chromatography/2.03%3A_Thin_Layer_Chromatography_(TLC)/2.3E%3A_Step-by-Step_Procedures_for_Thin_Layer_Chromatography"}},[_vm._v(" General TLC Procedure ")]),_vm._v(" in Chemistry Libretext. ")]),_c('p',{staticClass:"mb-2"},[_c('b',[_vm._v("[A]")]),_c('v-img',{staticStyle:{"max-width":"250px"},attrs:{"src":"/img/assignments/ChemUCI1LDQ2S1Q3_img1.png"}})],1),_c('p',{staticClass:"mb-3"},[_vm._v(" A: "),_c('v-select',{staticClass:"ml-1",staticStyle:{"display":"inline-block","max-width":"200px"},attrs:{"items":_vm.items,"item-text":"text","item-value":"value","label":"Select option:"},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{staticClass:"no-text-transform",attrs:{"content":item.text}})]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{staticClass:"no-text-transform",attrs:{"content":item.text}})]}}]),model:{value:(_vm.inputs.input1),callback:function ($$v) {_vm.$set(_vm.inputs, "input1", $$v)},expression:"inputs.input1"}})],1),_c('p',{staticClass:"mb-2"},[_c('b',[_vm._v("[B]")]),_c('v-img',{staticStyle:{"max-width":"250px"},attrs:{"src":"/img/assignments/ChemUCI1LDQ2S1Q3_img2.png"}})],1),_c('p',{staticClass:"mb-3"},[_vm._v(" B: "),_c('v-select',{staticClass:"ml-1",staticStyle:{"display":"inline-block","max-width":"200px"},attrs:{"items":_vm.items,"item-text":"text","item-value":"value","label":"Select option:"},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{staticClass:"no-text-transform",attrs:{"content":item.text}})]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{staticClass:"no-text-transform",attrs:{"content":item.text}})]}}]),model:{value:(_vm.inputs.input2),callback:function ($$v) {_vm.$set(_vm.inputs, "input2", $$v)},expression:"inputs.input2"}})],1),_c('p',{staticClass:"mb-2"},[_c('b',[_vm._v("[C]")]),_c('v-img',{staticStyle:{"max-width":"250px"},attrs:{"src":"/img/assignments/ChemUCI1LDQ2S1Q3_img3.png"}})],1),_c('p',{staticClass:"mb-3"},[_vm._v(" C: "),_c('v-select',{staticClass:"ml-1",staticStyle:{"display":"inline-block","max-width":"200px"},attrs:{"items":_vm.items,"item-text":"text","item-value":"value","label":"Select option:"},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{staticClass:"no-text-transform",attrs:{"content":item.text}})]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{staticClass:"no-text-transform",attrs:{"content":item.text}})]}}]),model:{value:(_vm.inputs.input3),callback:function ($$v) {_vm.$set(_vm.inputs, "input3", $$v)},expression:"inputs.input3"}})],1),_c('p',{staticClass:"mb-2"},[_c('b',[_vm._v("[D]")]),_c('v-img',{staticStyle:{"max-width":"250px"},attrs:{"src":"/img/assignments/ChemUCI1LDQ2S1Q3_img4.png"}})],1),_c('p',{staticClass:"mb-3"},[_vm._v(" D: "),_c('v-select',{staticClass:"ml-1",staticStyle:{"display":"inline-block","max-width":"200px"},attrs:{"items":_vm.items,"item-text":"text","item-value":"value","label":"Select option:"},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{staticClass:"no-text-transform",attrs:{"content":item.text}})]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{staticClass:"no-text-transform",attrs:{"content":item.text}})]}}]),model:{value:(_vm.inputs.input4),callback:function ($$v) {_vm.$set(_vm.inputs, "input4", $$v)},expression:"inputs.input4"}})],1),_c('p',{staticClass:"mb-2"},[_c('b',[_vm._v("[E]")]),_c('v-img',{staticStyle:{"max-width":"250px"},attrs:{"src":"/img/assignments/ChemUCI1LDQ2S1Q3_img5.png"}})],1),_c('p',{staticClass:"mb-3"},[_vm._v(" E: "),_c('v-select',{staticClass:"ml-1",staticStyle:{"display":"inline-block","max-width":"200px"},attrs:{"items":_vm.items,"item-text":"text","item-value":"value","label":"Select option:"},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{staticClass:"no-text-transform",attrs:{"content":item.text}})]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{staticClass:"no-text-transform",attrs:{"content":item.text}})]}}]),model:{value:(_vm.inputs.input5),callback:function ($$v) {_vm.$set(_vm.inputs, "input5", $$v)},expression:"inputs.input5"}})],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }